  import './App.css';
  import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; 
  import { Children, lazy, Suspense } from 'react';
  import Footer from "./components/Footer/Footer";
import PrivacyPolicy from "./components/Footer/PrivacyPolicy";
import TermsAndConditions from "./components/Footer/TermsAndConditions";
  import Navbaar from "./components/Navbaar/Navbaar";
  import ErrorBoundary from './components/errorboundary/ErrorBoundary'
  import { Provider } from 'react-redux';
  import { store } from './store/configration';
  import { PersistGate } from 'redux-persist/integration/react';
  import image2 from  "./assets/images/Vanilla-1.4s-280px.gif"
  // import Invoice from './components/Invoice    Vanilla-1.4s-280px.gif';
  const Login = lazy(() => import('./components/login/Login'))
  const Home = lazy(() => import('./components/Home/home'));
  const Dashboard = lazy(()=>import('./components/DashboardFile/dashboard'))
  const Member = lazy(() => import('./components/member/Member'))
  const SingleMemberBookPrinting = lazy(() => import('./components/member/SingleMemberShares'))
  const SingleMemberTransactions = lazy(() => import('./components/member/SingleMemberTransactions'))
  const SingleMemberLoan = lazy(() => import('./components/member/SingleMemberLoan'))
  const SingleLoanCalculator = lazy (()=>import('./components/member/SingleLoanCalculator'))
  const LoanRequestForm = lazy (()=>import('./components/member/LoanRequestForm'))
  const LoanForm = lazy (()=>import('./components/member/LoanForm'))
  const MemberForm1 = lazy (()=>import('./components/member/MemberForm1'))
  const MemberForm2 = lazy (()=>import('./components/member/MemberForm2'))
  const MemberForm3 = lazy (()=>import('./components/member/MemberForm3'))
  const GuranteeForm = lazy(()=>import('./components/member/GuranteeForm'))
  const ViewRequests = lazy(()=>import('./components/member/ViewRequests'))
  const ParentTabs = lazy(()=>import('./components/member/ParentTabs'));
  const Add = lazy(() => import('./components/add/Add'))
  const Forgetpassword = lazy(()=>import('./components/member/Forgetpassword'))
  const MemberViewRequests = lazy(() => import('./components/member/MemberViewRequests'));
  const WhatsApp = lazy(() => import('./components/member/WhatsApp'));
const UpdateProfile = lazy(() => import('./components/member/UpdateProfile.jsx'));
  const Administration = lazy(() => import('./components/administration/Administration.jsx'));
const AlternateMembers = lazy(() => import('./components/administration/AlternateMembers.jsx'));
const Expenditure = lazy(() => import('./components/administration/Expenditure.jsx'));
const ShareDetails = lazy(() => import('./components/administration/ShareDetails.jsx'));
const EmiDetails = lazy(() => import('./components/administration/EmiDetails.jsx'));

  const USER_TYPES = {
    PUBLIC:"Public User",
    NORMAL:"Normal User",
    ADMIN_USER:"Admin User"
  }
  const CURRENT_USER = USER_TYPES.ADMIN_USER

  function App() {
    return (
      <div className="app">
        <Provider store={store}>
          <Router>
            <ErrorBoundary>
              <Suspense fallback={
                <div className="d-flex align-items-center justify-content-center vh-100" >
                <img src={image2}></img>
                  </div>
                  }> 
                  <PersistGate persistor={store.__PERSISTOR} loading={null}>
                    <Navbaar style={{ margin: '200%' }} /> 
                    <div className='content'>
                      <Routes>
                      <Route path="/member/login" element={<Login USER={USER_TYPES.NORMAL} />} />
                      
                      <Route path="/admin/login" element={<Login 
                      USER={USER_TYPES.ADMIN_USER}/>} />

                  

                      <Route path="/" element={<Dashboard    />} />

                      <Route path='/member' element={<Member  AuthorizedElement={AuthorizedElement}/>} />

                      <Route path='/member/:id/shares' element={<SingleMemberBookPrinting  />} />

                      <Route path='/member/:id/transactions/:loan_id/:loan_type' element={<SingleMemberTransactions  />} />

                      <Route path='/member/:id/loan' element={<SingleMemberLoan />} />

                      <Route path='/member/:id/loanCalculator' element={<SingleLoanCalculator />} />

                      <Route path='/member/:id/loanRequest' element={<LoanRequestForm />} />

                      <Route path='/member/:id/loanForm' element={<LoanForm />} />

                      <Route path='/member/:id/loanform1' element={<MemberForm1 />} />

                      <Route path='/member/:id/loanform2' element={<MemberForm2 />} />

                      <Route path='/member/:id/loanform3' element={<MemberForm3 />} />

                      <Route path='/member/:id/gurantorForm' element={<GuranteeForm />} />

                      <Route path='/member/:id/memberViewRequests' element={<MemberViewRequests />} />

                      <Route path='/viewRequests' element={<ViewRequests />} />
                      
                      <Route path='/member/:id/pages' element={<ParentTabs  />} />
                      
                      <Route path='/add' element={<Add />} />
	     <Route path='/privacy-policy' element={<PrivacyPolicy />} />
	     <Route path='/terms-and-conditions' element={<TermsAndConditions />} />

                      <Route path='/forgetpassword' element={<Forgetpassword />} />

                      <Route path='/whatsAppMessages' element={<WhatsApp />} />

	                        <Route path='/updateProfile' element={<UpdateProfile />} />
	                        <Route path='/admindata' element={<Administration />} />
                    <Route path='/alternatemembers' element={<AlternateMembers />} />
                    <Route path='/expenditure' element={<Expenditure />} />
	                        <Route path='/shares-details' element={<ShareDetails />} />
                    <Route path='/emi-details' element={<EmiDetails />} />



                      <Route path="*" element={<img width="100%" height="750px" src="https://miro.medium.com/max/1400/1*zBFBJktPD3_z0S_35kO5Hg.gif" alt="not found" />} />

                      </Routes>  
                    </div>
                    <Footer />   
                  </PersistGate>
              </Suspense>
            </ErrorBoundary>
          </Router>
        </Provider>
      </div>
    );
  }


  function AuthorizedElement({ children, allowedUserTypes }) {
    const isUserAuthorized = allowedUserTypes.includes(CURRENT_USER);
  
    if (isUserAuthorized) {
      return <>{children}</>;
    }
  }
  export default App;
